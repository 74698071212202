var swiper = new Swiper(".mySwiper", {
  slidesPerView: 1, // Показывать 2 слайда по горизонтали
  grid: {
    rows: 2, // Показывать 2 строки
    fill: "row",
  },
  spaceBetween: 10, // Расстояние между слайдами
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  loop: true, // Повторять слайды
  slidesPerGroup: 1,
  breakpoints: {
    901: {
      slidesPerView: 2, // Показывать 1 слайд по горизонтали
      grid: {
        rows: 2, // Показывать 1 строку
      },
      slidesPerGroup: 2,
      spaceBetween: 30, // Расстояние между слайдами
    },
  },
});
