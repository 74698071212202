
document.querySelectorAll('.accordion-header').forEach(header => {
  header.addEventListener('click', () => {
    const accordionItem = header.parentElement;

    if (accordionItem.classList.contains('active')) {
      accordionItem.classList.remove('active');
    } else {
      document.querySelectorAll('.accordion-item.active').forEach(activeItem => {
        activeItem.classList.remove('active');
      });
      accordionItem.classList.add('active');
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const burger = document.querySelector('.burger');
  const navPopup = document.querySelector('.nav-popup');
  const navPopupClose = document.querySelector('.nav-popup__close');

  burger.addEventListener('click', () => {
    burger.classList.toggle('is-active');
    navPopup.classList.toggle('is-active');
    document.body.style.overflow = navPopup.classList.contains('is-active') ? 'hidden' : 'auto';
  });

  navPopupClose.addEventListener('click', () => {
    burger.classList.remove('is-active');
    navPopup.classList.remove('is-active');
    document.body.style.overflow = 'auto';
  });
});





